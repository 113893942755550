  html {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 40px 0 0 0;
    padding: 0;
    background: url('../src/static/confectionary.png'), #F2F2F2;
    background-repeat: repeat;
    font-family: 'Open Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.6rem;
  }
  a {
    text-decoration: none !important;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  /* [type=button]{
    -webkit-appearance: none !important;
  } */
  
